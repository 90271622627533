import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Button, Result } from 'antd';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  updateCountry,
  updateJobseekerHash,
  updateRedirect,
  updateSource
} from '../state/actions';
import { store } from '../state/store';
import { validateIdentifier } from '../common/api';
import Helmet from 'react-helmet';
import { IState } from '../state/types';
import smartlookClient from 'smartlook-client';

Sentry.init({
  dsn:
    'https://6dcd7c5d9785424c863572019d1d5849@o504175.ingest.sentry.io/5646965'
});

const AuthWrapper: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const hash = useSelector((state: IState) => state.jobseekerHash);
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const checkIdentifier = async () => {
      const URLParams = new URLSearchParams(window.location.search);
      const identifier = URLParams.get('id');
      const jobseekerKey = identifier || hash;

      try {
        if (jobseekerKey) {
          const isValid = await validateIdentifier(jobseekerKey);
          if (isValid) dispatch(updateJobseekerHash(jobseekerKey));
          else {
            throw Error;
          }

          smartlookClient.init('39497d33b4f8083f1134887c8811b91cb4b5af8c');

          const source = URLParams.get('source');
          dispatch(updateSource(source));
          const redirect = URLParams.get('redirect');
          if (redirect) {
            dispatch(updateRedirect(redirect));
          } else {
            // For Safety
            dispatch(updateRedirect(null));
          }
          const country = URLParams.get('country');
          if(country) {
            dispatch(updateCountry(country));
          } else {
            // For Safety
            dispatch(updateCountry(''))
          }
        } else {
          dispatch(updateJobseekerHash(null));
          throw Error;
        }
      } catch {
        setValid(false);
      }
    };

    checkIdentifier();
  }, []);

  if (!valid)
    return (
      <div style={s.flexDiv}>
        <Result
          status="error"
          title="Please use the link from our platforms"
          extra={[
            <a href="https://workclass.co/auth/login?redirect=resume" key="1">
              <Button type="link">
                or sign up on WorkClass.co to gain access
              </Button>
            </a>
          ]}
        />
      </div>
    );

  return <>{children}</>;
};

const ReduxWrapper: React.FC = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

const SEOWrapper: React.FC = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Resume Builder | WorkClass</title>
      </Helmet>

      {children}
    </>
  );
};

const Wrapper: React.FC = ({ children }) => {
  return (
    <SEOWrapper>
      <ReduxWrapper>
        <AuthWrapper>{children}</AuthWrapper>
      </ReduxWrapper>
    </SEOWrapper>
  );
};

export default Wrapper;

const s: Stylesheet = {
  flexDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh'
  }
};
