export const isBrowser = (): boolean => typeof window !== 'undefined';

const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth'
];
const deca = [
  'twent',
  'thirt',
  'fort',
  'fift',
  'sixt',
  'sevent',
  'eight',
  'ninet'
];

export const stringifyNumber = (n: number) => {
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
};

// Returns a Date object give month and year(default day 02)
export const combineMonthYear = (month: string, year: string) => {
  const monthInt = parseInt(month);
  const yearInt = parseInt(year);
  return new Date(yearInt, monthInt - 1, 2);
};

// Checks if valid url
export const isValidHttpUrl = (urlToCheck: string) => {
  let url;

  try {
    url = new URL(urlToCheck);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

// Check if url provided is a workclass.co url
export const isWorkClassUrl = (wcUrl: string) => {
  if (isValidHttpUrl(wcUrl)) {
    return wcUrl.includes('workclass');
  }

  return false;
};
