export interface IState {
  // Enter your typing here.
  persistence: Record<string, unknown>;
  summary: string;
  workExperience: IWorkExperience[];
  education: IEducation[];
  qualifications: string[];
  jobseekerHash: string;
  source: string;
  redirect?: string;
  country?: string;
}

/**
 * Action Types
 */

export enum EActionType {
  UPDATE_PERSISTENT_DATA,
  CLEAR_PERSISTENT_DATA,
  //
  UPDATE_SOURCE,
  UPDATE_REDIRECT,
  UPDATE_COUNTRY,
  UPDATE_JOBSEEKERHASH,
  UPDATE_WORK_EXPERIENCE,
  UPDATE_SUMMARY,
  UPDATE_EDUCATION,
  UPDATE_QUALIFICATION,
  CLEAR_RESUME
}
export interface IAction {
  type: EActionType;
}

export interface IActionUpdatePersistence extends IAction {
  data: {
    key: string;
    value: unknown;
  };
}

export interface IActionUpdateWorkExperience extends IAction {
  data: IWorkExperience[];
}

export interface IActionUpdateEducation extends IAction {
  data: IEducation[];
}

export interface IActionUpdateQualifications extends IAction {
  data: string[];
}

export interface IActionUpdateJobseekerHash extends IAction {
  data: string;
}

export interface IActionWebsiteUri extends IAction {
  data: string;
}

export interface IActionWebsiteCountry extends IAction {
  data: string;
}

