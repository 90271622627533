/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// import * as React from 'react';
// import { Provider } from 'react-redux';
// import { store } from './src/state/store';

// Other static dependencies to import
import 'firebase/auth';
import './src/static/css/global.css';

// Wrappers
// export const wrapRootElement = ({ element }) => {
//   return <Provider store={store}>{element}</Provider>;
// };

export const onServiceWorkerUpdateReady = () => {
  localStorage.removeItem('redux-state');

  window.alert(
    `This application has been updated. we are reloading your page to get the latest version`
  );
  window.location.reload();
};
