import {
  IState,
  IAction,
  EActionType,
  IActionUpdatePersistence,
  IActionUpdateWorkExperience,
  IActionUpdateEducation,
  IActionUpdateQualifications,
  IActionUpdateJobseekerHash,
  IActionWebsiteUri,
  IActionWebsiteCountry
} from './types';

function reducer<T extends IAction>(state: IState, action: T): IState {
  let data;
  switch (action.type) {
    case EActionType.UPDATE_PERSISTENT_DATA:
      data = ((action as unknown) as IActionUpdatePersistence).data;
      return {
        ...state,
        persistence: {
          ...state.persistence,
          [data.key]: data.value
        }
      };
    case EActionType.CLEAR_PERSISTENT_DATA:
      return {
        ...state,
        persistence: {}
      };
    case EActionType.UPDATE_SOURCE:
      data = ((action as unknown) as IActionUpdateJobseekerHash).data;
      return {
        ...state,
        source: data
      };
    case EActionType.UPDATE_REDIRECT:
      data = ((action as unknown) as IActionWebsiteUri).data;
      return {
        ...state,
        redirect: data
      };
    case EActionType.UPDATE_COUNTRY:
      data = ((action as unknown) as IActionWebsiteCountry).data;
      return {
        ...state,
        country: data
      };
    case EActionType.UPDATE_JOBSEEKERHASH:
      data = ((action as unknown) as IActionUpdateJobseekerHash).data;
      return {
        ...state,
        jobseekerHash: data
      };
    case EActionType.UPDATE_SUMMARY:
      data = ((action as unknown) as IActionUpdateJobseekerHash).data;
      return {
        ...state,
        summary: data
      };
    case EActionType.UPDATE_WORK_EXPERIENCE:
      data = ((action as unknown) as IActionUpdateWorkExperience).data;
      return {
        ...state,
        workExperience: data
      };
    case EActionType.UPDATE_EDUCATION:
      data = ((action as unknown) as IActionUpdateEducation).data;
      return {
        ...state,
        education: data
      };
    case EActionType.UPDATE_QUALIFICATION:
      data = ((action as unknown) as IActionUpdateQualifications).data;
      return {
        ...state,
        qualifications: data
      };

    case EActionType.CLEAR_RESUME:
      return {
        ...state,
        qualifications: [],
        education: [],
        workExperience: []
      };
    default:
      return state;
  }
}

export { reducer };
