import {
  IAction,
  EActionType,
  IActionUpdatePersistence,
  IActionUpdateWorkExperience,
  IActionUpdateEducation,
  IActionUpdateQualifications,
  IActionUpdateJobseekerHash,
  IActionWebsiteUri,
  IActionWebsiteCountry
} from './types';

export function updatePersistentData(
  key: string,
  value: unknown
): IActionUpdatePersistence {
  return {
    type: EActionType.UPDATE_PERSISTENT_DATA,
    data: {
      key: key,
      value: value
    }
  };
}

export function clearPersistence(): IAction {
  return {
    type: EActionType.CLEAR_PERSISTENT_DATA
  };
}

export function updateSummary(summary: string): IActionUpdateJobseekerHash {
  return {
    type: EActionType.UPDATE_SUMMARY,
    data: summary
  };
}

export function updateWorkExperience(
  workExperience: IWorkExperience[]
): IActionUpdateWorkExperience {
  return {
    type: EActionType.UPDATE_WORK_EXPERIENCE,
    data: workExperience
  };
}

export function updateEducation(
  education: IEducation[]
): IActionUpdateEducation {
  return {
    type: EActionType.UPDATE_EDUCATION,
    data: education
  };
}

export function updateQualifications(
  qualifications: string[]
): IActionUpdateQualifications {
  return {
    type: EActionType.UPDATE_QUALIFICATION,
    data: qualifications
  };
}

export function updateJobseekerHash(data: string): IActionUpdateJobseekerHash {
  return {
    type: EActionType.UPDATE_JOBSEEKERHASH,
    data: data
  };
}

export function updateSource(data: string): IActionUpdateJobseekerHash {
  return {
    type: EActionType.UPDATE_SOURCE,
    data: data
  };
}

export function updateRedirect(data: string): IActionWebsiteUri {
  return {
    type: EActionType.UPDATE_REDIRECT,
    data: data
  };
}

export function updateCountry(data: string): IActionWebsiteCountry {
  return {
    type: EActionType.UPDATE_COUNTRY,
    data: data
  };
}

export function clearResumeFields(): IAction {
  return { type: EActionType.CLEAR_RESUME };
}
