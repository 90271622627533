import { combineMonthYear } from '../utils';
import moment from 'moment';
import { get, post } from './main.api';

export async function validateIdentifier(identifier: string): Promise<boolean> {
  const request: IRequest = {
    endpoint: `validate/${identifier}`
  };
  const response = await get<{ success: boolean }>(request);
  return response.success;
}

export async function generateResume(
  identifier: string,
  resume: {
    summary: string;
    workExperience: IWorkExperience[];
    education: IEducation[];
    qualifications: string[];
  },
  source: string,
  persistence: Record<string, unknown>
): Promise<{ success: boolean; url?: string }> {
  if (!identifier) throw Error('No Identifier Supplied');

  /**
   * This format is to follow the mobile app's datetime format.
   * Reason:
   * The function that creates the resume needs specific formatting so that
   * it does not crash. One of which is the datetime format.
   */
  const workExperienceObj = resume.workExperience.map(x => ({
    ...x,
    from: x.fromMonth
      ? moment(combineMonthYear(x.fromMonth, x.fromYear)).format('YYYY-MM-DD')
      : '',
    to: x.toYear
      ? moment(combineMonthYear(x.toMonth, x.toYear)).format('YYYY-MM-DD')
      : '',
    description: x.description.map(j => `- ${j}\n`).join(''),
    leavingReason:
      x.leavingReason === 'Others' ? x.leavingReasonText : x.leavingReason
  }));

  const educationObj = resume.education.map(x => ({
    ...x,
    degree_speciality: x.degree_speciality || 'Not Specified',
    // 1 is used as the default month as we only have year as input
    from: moment(combineMonthYear('1', x.fromYear)).format('YYYY-MM-DD'),
    to: x.toYear
      ? // 1 is used as the default month as we only have year as input
        moment(combineMonthYear('1', x.toYear)).format('YYYY-MM-DD')
      : ''
  }));

  workExperienceObj.forEach(workexp => {
    delete workexp.fromMonth;
    delete workexp.fromYear;
    workexp.toMonth ? delete workexp.toMonth : true;
    workexp.toYear ? delete workexp.toYear : true;
  });

  educationObj.forEach(workexp => {
    delete workexp.fromYear;
    workexp.toYear ? delete workexp.toYear : true;
  });

  const studentWorkExpObj = [
    {
      title: 'I am a Student',
      company: 'No Work Experience',
      from: '',
      to: '',
      description: ''
    }
  ];

  const request: IRequest = {
    endpoint: `generate/${identifier}`,
    data: {
      summary: resume.summary,
      workExperience: persistence?.student
        ? JSON.stringify(studentWorkExpObj)
        : JSON.stringify(workExperienceObj),
      education: JSON.stringify(educationObj),
      qualifications: JSON.stringify(resume.qualifications),
      source: source
    }
  };

  return await post<{ success: boolean; url?: string }>(request);
}
