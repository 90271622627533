// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-complete-tsx": () => import("./../../../src/pages/complete.tsx" /* webpackChunkName: "component---src-pages-complete-tsx" */),
  "component---src-pages-complete-upload-tsx": () => import("./../../../src/pages/complete-upload.tsx" /* webpackChunkName: "component---src-pages-complete-upload-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-qualifications-tsx": () => import("./../../../src/pages/qualifications.tsx" /* webpackChunkName: "component---src-pages-qualifications-tsx" */),
  "component---src-pages-summary-tsx": () => import("./../../../src/pages/summary.tsx" /* webpackChunkName: "component---src-pages-summary-tsx" */),
  "component---src-pages-work-experience-tsx": () => import("./../../../src/pages/work-experience.tsx" /* webpackChunkName: "component---src-pages-work-experience-tsx" */)
}

